import './style.css';
import axios from 'axios';
import { Map, View } from 'ol';
import { fromLonLat, transformExtent, toLonLat } from 'ol/proj';
import XYZ from "ol/source/XYZ";
import TileGrid from "ol/tilegrid/TileGrid";
import TileLayer from 'ol/layer/Tile';
import TileState from 'ol/TileState';
import { Control } from "ol/control";
import {Stroke, Style} from 'ol/style';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';
//import OSM from 'ol/source/OSM';
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
let production = true;
if (import.meta.env.MODE == 'development') {
  production = false;
}
let EBXMapURL = "https://api.desreid.com/ebxmap";
if (production == false) {
  EBXMapURL = "http://127.0.0.1:8000/ebxmap"
}
const titilerService = "https://tile.braeria.ch/";
//const titilerService = "https://titiler.xyz/";
//const titilerService = "http://127.0.0.1:8000/";
const EBXLayerName = 'ebxLayer';
const eeLayerNamePrefix = 'eeLayer';
const cogPrefix = 'https://geojson.braeria.ch/cogs/';
const npGeoJSONURI = 'https://geojson.braeria.ch/cairngorm_np.geojson';
const OSServiceURL = "https://api.os.uk/maps/raster/v1/zxy/";
const EBXURL = "https://earthengine.googleapis.com/v1alpha/projects/ebx-trial-dev/maps/3c61a9423ae4016e9ce86a7e8f6a0bcd-e5e20aa15ee4da7b54a440518d9d4001/tiles/"
const OSprojection = "EPSG:27700";
const peanut = "YtBO5ChcoLG5oW8MsB2RyrVRFGNRbMYZ";
const defaultOpacity = 0.6;
const defaultZoom = 3;
const tileRetries = 5;
const infoRetries = 40;
let opacityController = new AbortController();
proj4.defs(
  OSprojection,
  "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs"
);
register(proj4);
const centre = fromLonLat([-3.728828, 57.078167], OSprojection);
const escalatorLocation = fromLonLat([-3.7719497757262843, 57.087041125679576], OSprojection);
//const centre = fromLonLat([-5.4, 57.478167], OSprojection);
const cogInfoElement = document.getElementById('cogInfo');
// const ebxOpacityInput = document.getElementById('ebxOpacityInput');
// const ebxInfo = document.getElementById('ebxInfo');

const COGs =
  [
    {
      'id': 's2_2021np',
      'name': 'S2 Feb 2021 - NP',
      'url': 'braeriach/s2-cairngorm-np-202102.tif',
      //'url': `${cogPrefix}s2-cairngorm-np-202102.tif`,
      'palette': null,
      'bands': [
        { index: 3, min: 0, max: 1 },
        { index: 2, min: 0, max: 1 },
        { index: 1, min: 0, max: 1 },
      ]
    },
    {
      'id': 's2_2022np',
      'name': 'S2 Feb 2022 - NP',
      'url': 'braeriach/s2-cairngorm-np-202202.tif',
      'palette': null,
      'bands': [
        { index: 3, min: 0, max: 1 },
        { index: 2, min: 0, max: 1 },
        { index: 1, min: 0, max: 1 },
      ]
    },
        {
      'id': 's2_2023np',
      'name': 'S2 Feb 2023 - NP',
      'url': 'braeriach/s2-cairngorm-np-202302.tif',
      'palette': null,
      'bands': [
        { index: 3, min: 0, max: 1 },
        { index: 2, min: 0, max: 1 },
        { index: 1, min: 0, max: 1 },
      ]
    },
    {
      'id': 'tsc2021signed',
      'name': 'Terra Snow Feb 2021 - Scotland',
      'url': `${cogPrefix}cairgorm-500m-snow.tif`,
      'palette': 'pink',
      // example tile 9, 250, 155
      'bands': [
          { index: 1, min: 0, max: 100 }
        ]
    },
    {
      'id': 'landcover',
      'name': 'ESA Land Use - NP',
      'url': `${cogPrefix}esa-landcover-cairngorm-np.tif`,
      //'palette': 'set3_r',
      // https://developers.google.com/earth-engine/datasets/catalog/ESA_WorldCover_v100
      'definedPalette': {
          0: '#006400', // Tree cover
        [Math.round((20-10)*255/90)]: '#ffbb22', // Shrubland
        [Math.round((30-10)*255/90) - 1]: '#ffff4c', // Grassland
        [Math.round((40-10)*255/90)]: '#f096ff', // Cropland
        [Math.round((50-10)*255/90)]: '#fa0000', // Built Up
        [Math.round((60-10)*255/90) - 1]: '#b4b4b4', // Bare / sparse vegetation
        [Math.round((70-10)*255/90) - 1]: '#f0f0f0', // Snow and ice
        [Math.round((80-10)*255/90)]: '#0064c8', // Permanent water bodies
        [Math.round((90-10)*255/90) - 1]: '#0096a0', // Herbaceous wetland
        [Math.round((95-10)*255/90)]: '#00cf75', // Mangroves
        255: '#fae6a0' // Moss and lichen
      },
      'bands': [
        { index: 1, min: 10, max: 100 }
      ]
    },
    {
      'id': 'elev',
      'name': 'SRTM Elevation - NP',
      'url': `${cogPrefix}srtm-elevation-cairngorm-np.tif`,
      'palette': 'terrain',
      'bands': [
        { index: 1, min: 200, max: 1325 }
      ]
    },
    {
      'id': 'elev_classified',
      'name': 'SRTM Elevation Classified - Plateau',
      'url': `${cogPrefix}srtmCairngorm.tif`,
      'palette': 'accent',
      'bands': [
        { index: 6, min: 0, max: 3 }
      ]
    },
    {
      'id': 'slope_classified',
      'name': 'SRTM Slope Angle Classified - Plateau',
      'url': `${cogPrefix}srtmCairngorm.tif`,
      'palette': null,
      'definedPalette': {
        "0": "#00FF00",
        "63": "#0000FF",
        "127": "#FF0000",
        "191": "#000000",
        "255": "#FFBBBB"
      },
      'bands': [
        { index: 7, min: 0, max: 4 }
      ]
    },
  ];

const OSTileGrid = new TileGrid({
  resolutions: [
    896.0, 448.0, 224.0, 112.0, 56.0, 28.0, 14.0, 7.0, 3.5, 1.75, 0.875, 0.4375, 0.21875, 0.109375
  ],
  origin: [-238375.0, 1376256.0], // origin from example
});

const map = new Map({
  target: 'map',
  layers: [
    new TileLayer({
      name: 'Leisure',
      maxZoom: 9,
      source: new XYZ({
        url: `${OSServiceURL}Leisure_27700/{z}/{x}/{y}.png?key=${peanut}`,
        projection: OSprojection,
        tileGrid: OSTileGrid,
      }),
    }),
    new TileLayer({
      name: 'Outdoor',
      minZoom: 9,
      maxZoom: 13,
      source: new XYZ({
        url: `${OSServiceURL}Outdoor_27700/{z}/{x}/{y}.png?key=${peanut}`,
        projection: OSprojection,
        tileGrid: OSTileGrid,
      }),
    }),
  ],
  view: new View({
    center: centre,
    zoom: defaultZoom,
    projection: OSprojection,
    resolutions: OSTileGrid.getResolutions(),
  })
});

setupCOGDropdown();
addNationalParkLayer();
//addEBXLayer();
addEELayers();
addCustomControls();
map.on('rendercomplete', bRenderComplete);
map.on('loadstart', bLoadStart);
map.on('moveend', bMapMoveEnd)

function addEBXLayer() {
  let axios_config = {
    'method': 'get',
    'baseURL': EBXMapURL,
    'timeout': 4000,
    'responseType': 'json'
  }
  axios.request(axios_config)
    .then((response) => {
      let workflowRun = response.data[0];
      let projectName = workflowRun['project_name'];
      let startDate = new Date(workflowRun['start_date']);
      startDate = `${startDate.toDateString()}`;
      let endDate = new Date(workflowRun['end_date']);
      endDate = `${endDate.toDateString()}`;
      console.log(`Adding EBX Layer ${projectName}`);
      let ebxLayer = new TileLayer({
        name: EBXLayerName,
        //maxZoom: 9,
        source: new XYZ({
          url: `${workflowRun['map_url']}`,
        }),
        opacity: 0.6
      });
      map.addLayer(ebxLayer);
      ebxOpacityInput.addEventListener('input', addEBXLayerOpacityListener);
      ebxInfo.innerHTML = `${projectName}<br />${startDate}-${endDate}`;
  })
  
  
}

function addEELayers() {
  let axios_config = {
    'method': 'get',
    'baseURL': EBXMapURL,
    'timeout': 4000,
    'responseType': 'json'
  }
  axios.request(axios_config)
    .then((response) => {
      // get the first three results
      // which will be 
      // Landsat, then Snow probability, then S2
      for(let i = 0; i < 3; i++){
        let workflowRun = response.data[i];
        addEETileLayer(workflowRun, i)
      }  
  })
}

function addEETileLayer(workflowRun, i){
  let eeOpacityInput = document.getElementById(`eeOpacityInput${i + 1}`);
  let eeInfo = document.getElementById(`eeInfo${i + 1}`);
  let projectName = workflowRun['project_name'];
  console.log(`Adding Earth Engine Layer ${projectName}`);
  let startDate = new Date(workflowRun['start_date']);
  startDate = `${startDate.toDateString()}`;
  let endDate = new Date(workflowRun['end_date']);
  endDate = `${endDate.toDateString()}`;
  let eeLayerName = eeLayerNamePrefix + i;
  let opacity = 0;
  if (i == 2){
    opacity = 1
  }
  let ebxLayer = new TileLayer({
    name: eeLayerName,
    //maxZoom: 9,
    source: new XYZ({
      url: `${workflowRun['map_url']}`,
    }),
    opacity: opacity
  });
  map.addLayer(ebxLayer);
  
  eeOpacityInput.addEventListener('input', function (event) { addEELayerOpacityListener(event, eeLayerName, `eeOpacityOutput${i + 1}`)});
  eeInfo.innerHTML = `${projectName}<br />${startDate}-${endDate}`;
}

async function addNationalParkLayer() {
  console.log(`Fetching National Park boundary ${npGeoJSONURI}`);
  let response = await fetch(npGeoJSONURI);
  let npGeoJSONObject = await response.json();
  console.log(`Fetching NP boundary geojson complete`);
  let npLayer = new VectorLayer({
    name: 'NPBoundary',
    zIndex: 100,
    source: new VectorSource({
      features: new GeoJSON().readFeatures(npGeoJSONObject),
    }),
    style: new Style({
      stroke: new Stroke({
        color: '#00ff0099',
        width: 4,
      })
    }),
  })
  map.addLayer(npLayer);
}

function addCustomControls() {
  const eButton = document.createElement('button');
  eButton.innerHTML = 'E';
  eButton.setAttribute('title', 'Zoom to The Escalator');
  eButton.addEventListener('click', escalatorZoom);
  
  const eElement = document.createElement('div');
  eElement.className = 'escalator ol-unselectable ol-control';
  eElement.appendChild(eButton);

  let eControl = new Control({element: eElement})
  map.addControl(eControl);
}

function setupCOGDropdown() {
  let cogDropdownElement = document.getElementById('cogChoice');
  console.log(cogDropdownElement.value);
  COGs.forEach(function (cog, index) {
    let option = document.createElement("option");
    option.text = cog['name'];
    option.value = index;
    cogDropdownElement.add(option);
  })
  cogDropdownElement.addEventListener("change", addCOGLayerListener);
}

async function addCOGLayer(cog) {
  let [titilerURL, extent] = await generateTitilerURL(cog);
  console.log(`Adding COG layer with tiler URL: ${titilerURL}`);
  let tileSource = new XYZ({
      url: titilerURL,
      tileLoadFunction: titilerTileLoad
  })
  tileSource.on('tileloaderror', bTileLoadError);
  tileSource.on('tileloadstart', bTileLoadStart);
  tileSource.on('tileloadend', bTileLoadEnd);
  let cogTileLayer = new TileLayer({
    name: cog['id'],
    opacity: defaultOpacity,
    extent: extent,
    source: tileSource,
  })
  map.addLayer(cogTileLayer);
  document.getElementById('layerOpacity').style.visibility = 'visible';
  const cogOpacityInput = document.getElementById('cogOpacityInput');
  // remove the old listener
  opacityController.abort();
  opacityController = new AbortController(); // global that will still be around when we want to abort the new listener
  const { signal } = opacityController;
  cogOpacityInput.addEventListener('input', function (event) { updateCOGOpacityListener(event, cog['id']); }, { signal });
  var event = new Event('input');
  cogOpacityInput.dispatchEvent(event);
}

async function getCogInfo(cogURL, retryCount) {
  const cogInfoElement = document.getElementById('cogInfo');
  let infoURL = `${titilerService}cog/info?url=${cogURL}`;
  console.log("Calling titiler for the COG info at " + infoURL);
  let cogInfo = fetch(infoURL)
    .then(function (response) {
      if(response.ok)
      {
        return response.json();         
      }
      throw new Error(`Status ${response.status}`);
    })
    .then(function (json) {
      //console.log(`Coginfo: ${json}`);
      cogInfoElement.innerText = `Cog info retrieved on attempt ${retryCount}.`;
      return json;
    })
    .catch(function (error) {
      cogInfoElement.innerText = `Some sort of error (${error}). Retrying ${retryCount}.`;
      console.log('Problem getting COG info: ', error);
      return null;
    })
  return cogInfo;
}

async function generateTitilerURL(cog) {
  // first get info for the COG
  let i = 0;
  let bandLabels = [];
  cogInfoElement.innerText = 'Fetching extent info...';
  //CURL error: SSL_write() returned SYSCALL, errno = 32
  let cogInfo = null;
  do {
    /* if (i > 0) {
      await new Promise(r => setTimeout(r, 400));
    } */
    cogInfo = await getCogInfo(cog['url'], i);
    i++;
  }
  while (i <= infoRetries && !cogInfo);
  if (!cogInfo) { return null };
  console.log(`Cog information retrieved on attempt ${i}: `, cogInfo);
  let extent = transformExtent(cogInfo.bounds, "EPSG:4326" , "EPSG:27700");
  map.getView().fit(extent);
  let url = `${titilerService}cog/tiles/{z}/{x}/{y}.png?url=${cog['url']}`;
  if (cog['palette']) {
    url += `&colormap_name=${cog['palette']}`;
  }
  if (cog['definedPalette']) {
    let encodedColours = encodeURIComponent(JSON.stringify(cog['definedPalette']));
    url += `&colormap=${encodedColours}`;
  }
  //let statsURL = `${titilerService}cog/statistics?url=${cog['url']}`;
  cog['bands'].forEach(
    function (bandDict) {
      let bandLabel = cogInfo['band_descriptions'][bandDict['index'] - 1][0];
      bandLabels.push(bandLabel);
      let bandDescription = cogInfo['band_descriptions'][bandDict['index'] -1][1];
      console.log(`Band chosen: Label: ${bandLabel} Description: ${bandDescription}`);
      url = `${url}&bidx=${bandDict['index']}`;
      //statsURL = statsURL + `&bidx=${bandKey}`
    }
  );
  cog['bands'].forEach(
    function (bandDict) {
      url = `${url}&rescale=${bandDict['min']},${bandDict['max']}`;
    }
  )
  /* fetching stats is uber slow */
  /* console.log(`Fetching stats for COG with ${statsURL}`);
  cogInfoElement.innerText = 'Fetching stats info...';
  let response2 = await fetch(statsURL);
  let cogStats = await response2.json();
  console.log(cogStats);
  bandLabels.forEach(
    function (label) {
      //url = `${url}&rescale=${cogStats[label]['min']},${cogStats[label]['percentile_98']}`
    }
  ) */
  console.log(`url: ${url}`);
  cogInfoElement.innerText = 'Ready';
  return [url, extent];
}

function getLayerByName(layerName){
  let foundLayer = null;
  //console.log(`Request to fetch layer by name ${layerName}`);
  map.getLayers().forEach(
    function(layer, index){
      //console.log(`Checking layer ${index}`);
      if (layer){
        if (layer.get('name') == layerName){
          // console.log(`Found layer ${layerName}`);
          foundLayer = layer;
        }
      }
    }
  )
  return foundLayer;
}

// LISTENERS

function bTileLoadEnd(event) {
  cogInfoElement.innerText = `Finished loading tile ${event.tile.getTileCoord()}`;
}

function bTileLoadStart(event) {
  cogInfoElement.innerText = `Started loading tile ${event.tile.getTileCoord()}`;
}

function bTileLoadError(event) {
    cogInfoElement.innerText = `Error loading tile ${event.tile.getTileCoord()}`
    console.log(`error loading tile ${event.tile.getTileCoord()} ${event.target.zDirection}`);
}

function bRenderComplete(event) {
  cogInfoElement.innerText = `Render Complete`;
  // console.log("Render Complete");
}

function bMapMoveEnd() {
  let centre = map.getView().getCenter();
  let centreLatLon = toLonLat(centre, OSprojection);
  console.log(`Lat: ${centreLatLon[1]} Lon ${centreLatLon[0]}`);
}

function bLoadStart(event) {
  cogInfoElement.innerText = `Render Starting`;
}

function escalatorZoom(event) {
  console.log("Escalator requested");
  map.getView().setCenter(escalatorLocation);
  map.getView().setZoom(9);
}

function updateCOGOpacityListener(event, layerName) {
  let cogOpacityOutput = document.getElementById('cogOpacityOutput');
  const opacity = parseFloat(event.target.value);
  //console.log(`Map has ${map.getLayers().getLength()} layers`);
  //console.log(`Setting opacity of layer ${layerName} to ${opacity}`);
  getLayerByName(layerName).setOpacity(opacity);
  cogOpacityOutput.innerText = opacity.toFixed(2);
}

function addEBXLayerOpacityListener(event) {
  let ebxOpacityOutput = document.getElementById('ebxOpacityOutput');
  const opacity = parseFloat(event.target.value);
  getLayerByName(EBXLayerName).setOpacity(opacity);
  ebxOpacityOutput.innerText = opacity.toFixed(2);
}

function addEELayerOpacityListener(event, eeLayerName, eeOpacityOutput) {
  let ebxOpacityOutput = document.getElementById(eeOpacityOutput);
  const opacity = parseFloat(event.target.value);
  getLayerByName(eeLayerName).setOpacity(opacity);
  ebxOpacityOutput.innerText = opacity.toFixed(2);
}

async function addCOGLayerListener(event) {
  COGs.map(function (cog) {
      console.log("removing layer " + cog['id']);
      map.removeLayer(getLayerByName(cog['id']));
  })
  if (event.target.value == -1) {
    console.log("No COG");
    document.getElementById('layerOpacity').style.visibility = 'hidden';
  } else {
    console.log("Switching to COG " + COGs[event.target.value]['name']);
    addCOGLayer(COGs[event.target.value]);
  }
}

// TILE LOAD FUNCTIONS 

async function titilerTileLoad(tile, uri) {
  console.log(`Tile request ${tile.getTileCoord()} from ${uri}`);
  let i = 0;
  let status = 0;
  //let tileResult = null;
  do {
    status = await tileFetchAttempt(tile, uri);
    i++;
    console.log(`Attempt ${i} at ${tile.getTileCoord()} ${status}`);
  }
  while (i < tileRetries && status == 500);
  if (status > 299) {
    console.log(`Giving up on ${tile.getTileCoord()} ${status} after ${i - 1} retries`);
    tile.setState(TileState.ERROR)
  }
}

function tileFetchAttempt(tile, uri) {
  let status = fetch(uri)
    .then(function (response) {
      if (response.ok){
        return response.blob();         
      }
      throw new Error(response.status);
    })
    .then(function (blob) {
      let message = `200 for tile ${tile.getTileCoord()}`;
      cogInfoElement.innerText = message;
      console.log(message);
      tile.getImage().src = URL.createObjectURL(blob);
      return 200;
    })
    .catch(function (error) {
      let message = `${error.message} for tile ${tile.getTileCoord()}`;
      if (error.message == "500") {
        // for other errors like 500 it's worth retrying with titiler
        // set state to error and the iteration will do another loop
        message += ": will retry";
      }
      cogInfoElement.innerText = message;
      console.log(message);
      return parseInt(error.message);
    })
  return status;
}

function ztileFetchAttempt(tile, uri) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.addEventListener('loadend', function (evt) {
    const data = this.response;
    if (data !== undefined) {
      tile.getImage().src = URL.createObjectURL(data);
    } else {
      console.log(`loadend status ${this.status}`);
      tile.setState(TileState.ERROR);
    }
  });
  xhr.addEventListener('error', function () {
    console.log(`error status ${this.status}`);
    tile.setState(TileState.ERROR);
  });
  xhr.open('GET', uri);
  xhr.send();
}